import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tweets`}</h2>
    <blockquote className="twitter-tweet">
  <p lang="en" dir="ltr">
    Hey
    <a href="https://twitter.com/hashtag/roamcult?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamcult
    </a>
    !<br />
    <br />
    I&#39;d like to share my first Roam JS Extension today - An integration with
    Google Calendar!
    <br />
    <br />
    By clicking on an &quot;Import Google Calendar&quot; button, you could import
    your calendar&#39;s events for today. For more info on how to use, read here:
    <a href="https://t.co/rZ78pnar5G">https://t.co/rZ78pnar5G</a>
    <br />
    <br />
    1/ <a href="https://t.co/8Xj4r5nugb">pic.twitter.com/8Xj4r5nugb</a>
  </p>
  &mdash; David Vargas (@dvargas92495)
  <a href="https://twitter.com/dvargas92495/status/1301175953897467905?ref_src=twsrc%5Etfw">
    September 2, 2020
  </a>
    </blockquote>
    <blockquote className="twitter-tweet">
  <p lang="en" dir="ltr">
    Just shipped our biggest new feature in a very long time.
    <br />
    <br />
    Makes Anki style SRS flashcards a first class feature of Roam, solves repeating
    tasks, continuing a project across multiple days, an overflowing backlog of TODOS,
    and much more.
    <br />
    <br />
    Will demo to believers this weekend
    <br />
    <br />∆
  </p>
  &mdash; Conor White-Sullivan (@Conaw) <a href="https://twitter.com/Conaw/status/1302217025167585280?ref_src=twsrc%5Etfw">
    September 5, 2020
  </a>
    </blockquote>
    <blockquote className="twitter-tweet">
  <p lang="en" dir="ltr">
    Oh fuck this is genius. Here&#39;s how it works: it _moves_ the original
    block to the new date, and leaves an aliased copy in its original place. So
    ((ABCD)) from today moves to tomorrow, leaves ((YHDZ)) with identical
    content and link to ((ABCD)) in its place. This is _amazing_.
    <a href="https://t.co/FRrv9cV3Uy">https://t.co/FRrv9cV3Uy</a>
  </p>
  &mdash; Cortex Futura (@cortexfutura) <a href="https://twitter.com/cortexfutura/status/1302228722028867584?ref_src=twsrc%5Etfw">September 5, 2020</a>
    </blockquote>
    <h2>{`Websites`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.unisonweb.org/"
      }}>{`The Unison language`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1e6f7fd38668f8731acb658f4b567fe0/719d0/unison-site.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAADQ0lEQVQ4y42SbUyVdRjG/8jYWtM0dWtNl9aH+pJzQVMnoLyEiHCO5xzO4eUcXg9wXiBbouTB2eZ8wwYz53hHUyCwXKn1IRURDhisoXP6wS2hMTWnFrPNWis58Py6n+cctY8+27X7/7brue7rvhSR789/HzPNE1780yL434mmoUZOXaWx9AQt/i5afF10fNgrOPmsHjXWUrfo6DXO2yp7BF/S6u+mvaqXVk83E2OTBqnSyVYrM5tUMRtUAWnKJTWMNOV8hhS5W6+KSJWabty5pBawURCv7JxrvxwmPPbRV3JYSO58H475XhxzvWxWZVhVBY4Yv8CHbY6f4rluti3NpfQVNya5swmyBZaYKopUCXe/GwkTdkgrqSqfimWf4HlzB+4l2/C8EaBkcTWZ8tAq5Jmqkp3LzTSvTpa9ldbKr7l9/Q65L1WRo0q5FeuAwSCzOqHuTYrKY1diPZ9ZWziU10Gju5OGnDbKXq8h/+UqUeTFvyhfyGw0+b5lavI3Rr65xo0r97iZVAYJ6cxcirTcIabqfu1JP8yR4i84XHCMg5Zmg/x49SnqNjeRHeUhQ5XTXNHDo7tTeN7ZLS07+Of7fvhlnJCpEC4MGjNX+gT1luvMTbTr0/P3oP/kgKmRmvf3sz12H/ZoH655W5h9Mk134DRZonRiVS6k2wnNzKK1daKd/SGiMEJYvXI3tWv2UxO3l13r6gmsOUj2HC8WUWZTHmm7RM7qmPz1b+7Xfg4pZmZ/nkDTW11ngv6h5wr1WDhfDcikP8W1aCdFC7dSuOBjw8PS17Zjj9IJfdjl3W3vHvjjd0IPpmQQPwqxlevvZjF9cTisUA9rssSmIdbEGXMcDe9liCq/EQtLJBomUeiMrmA8vkAGsJHQjn1wrh8tycJYnJ3zb5uZ7ht6mkNdYRG1b9mpX5lMYJkFW5SoiZZMCnRlzhgv42tdYMphZpMTUm3SphktI4/HG/L5K9GC1h9R2FTeJUm38oEoSRAkKbcR9AxBmsApCbi1wgLJmYTixauELDTxTBNCEmW/XuqqNDg/EPZw/KdJ+tqHGeoeZahrhKBg8MQowc5R+o6PcueMmD4wyExfEC4G0SJ4vpZh6GT3HxoK/wOeeM780eMmhQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Unison",
            "title": "Unison",
            "src": "/static/1e6f7fd38668f8731acb658f4b567fe0/5a190/unison-site.png",
            "srcSet": ["/static/1e6f7fd38668f8731acb658f4b567fe0/772e8/unison-site.png 200w", "/static/1e6f7fd38668f8731acb658f4b567fe0/e17e5/unison-site.png 400w", "/static/1e6f7fd38668f8731acb658f4b567fe0/5a190/unison-site.png 800w", "/static/1e6f7fd38668f8731acb658f4b567fe0/c1b63/unison-site.png 1200w", "/static/1e6f7fd38668f8731acb658f4b567fe0/29007/unison-site.png 1600w", "/static/1e6f7fd38668f8731acb658f4b567fe0/719d0/unison-site.png 1779w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://roamresearch.com/#/app/hyperfinelabs/page/TYt89wtA7"
      }}>{`Hyperfine Village`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/196ded9fc7c92219917476ddaf3d2d25/d61c2/Hyperfine.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACGUlEQVQ4y5WTy2sTURTG8ydYpEofeTXt5DWTTPqY6Zh00jSWqEWXIrhw40IXWigV9A9wI9iN0C66qw+UUo0QtSgoiFIEUXHtQqymSWzSNpnUQhM/z706UpK0pAMf995z5v7ud+aesVgFCTZ3gIvN7Z4ggqoOiRQ8MoKAEkFntx+HHV4ccrjRahfQQWvzfasg8rGzR4SdYhYzYULbXT5cv3ETC09TmHuUxL35h5iZu4Pp2Wncvnsf8wuPcer0WbTaBH74TjMOWlvMgKm2Li8Wn78AeyrbWyiViqjSPLe6jN/VKo+PX7mGA21OOH1yXXV1QObwAbkwypvIZjLI5bJIrywjk1lBOv0dxY0iLlyaQEtHFxzepoFJclbCWj6P9cIqvn77gnzhJwcahoGL45Noad8HMJl6hu1KBYW1dWwUS9j8ZSCb+4FyucxLvjx59W/JzQKnbs3g3fsPePX6zT+9xJPFFN4uLeHjp884c+48Dlq7m3PIWkJShhBNnIQaHYUWS3BF4icQjh+HO6jwFqndx4Fm2/wPspESPeowJD2B/nAMij4KeVCHJ6SSBrkLs81qe9jWCGinhFMbgSc2Bo0cKvpR+MmxOBCBRHL5exuCdgUyhwIB++JjGCCHAXUIbjUKSRvmTnc6rIWyb9q4ZAL2xo5BJpisRREikEy/otgf3hPoEvvqL4Ul2X/MSmSbnb4QFzvdvNXdSmb6A2+h5yJVuKyGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Hyperfine Village",
            "title": "Hyperfine Village",
            "src": "/static/196ded9fc7c92219917476ddaf3d2d25/5a190/Hyperfine.png",
            "srcSet": ["/static/196ded9fc7c92219917476ddaf3d2d25/772e8/Hyperfine.png 200w", "/static/196ded9fc7c92219917476ddaf3d2d25/e17e5/Hyperfine.png 400w", "/static/196ded9fc7c92219917476ddaf3d2d25/5a190/Hyperfine.png 800w", "/static/196ded9fc7c92219917476ddaf3d2d25/c1b63/Hyperfine.png 1200w", "/static/196ded9fc7c92219917476ddaf3d2d25/29007/Hyperfine.png 1600w", "/static/196ded9fc7c92219917476ddaf3d2d25/d61c2/Hyperfine.png 1800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Reddit Posts`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.reddit.com/r/RoamResearch/comments/igydmn/roam_research_for_screenwriting_proof_of_concept/?utm_medium=android_app&utm_source=share"
      }}>{`Roam Research for screenwriting?`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6076eede9db0026a9a87f3247a031ec7/e67a8/Screenwriter.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABzklEQVQoz22S20/iQBTGe6WIxmy1hUJxFQWtLcUY5WZkX/Sf0H0iwSAvRp80/s0aUbmUWz/PjEuXXZ3ky0w6Pb/vnDlH0K2f2C0ewj+qwSkdI+f4MO0czOz2tzLozrC3YGT+yJ4rxyUoagyrP3To6wYSyytQYxpESeYSRDk6S7JCUqFqccRIWnwJ8aUE35nYN3YnyJIEQRCg6zr2HAe1Wh3lcgWVShX1+gmq1Ro/F4s+PK+ITMZGOp2GQ//adhZWyoJpJqEoZCiKBJTJnaBxcvF9H93XV4zHYwwGA7x0u3h6fsbb+zu6dGar2WzCNEycnZ2TAZmQEQNzIEuOAWVZQoxKdV0Xo9EIQTDCZDJBGIaYzWYcNJ1O+d7pXEOj0o6pCtf1UCodYGcnj3liUYaappGjxzPr9QcICDwHMjEjttrtNjfP5wvIZjeQTKawTu+vqupXIHMMgoAHhuEsgv0PTCSW0Wj8wulpA2V63/19F4qsfA8cDoc8cBG2CGy1Wjyz+4dHtK7auLm9w8Xlb4jUEMaKgJ9v6EWBrNzFxRr1+YYdHlwo7MKg5uj6Gp+Qf5ryN0MXvV6PN4SBF9Xv96OS2Zhtbm7BstJ8jFI0OnPgB7CvmWmYoWf+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenwriter",
            "title": "Screenwriter",
            "src": "/static/6076eede9db0026a9a87f3247a031ec7/5a190/Screenwriter.png",
            "srcSet": ["/static/6076eede9db0026a9a87f3247a031ec7/772e8/Screenwriter.png 200w", "/static/6076eede9db0026a9a87f3247a031ec7/e17e5/Screenwriter.png 400w", "/static/6076eede9db0026a9a87f3247a031ec7/5a190/Screenwriter.png 800w", "/static/6076eede9db0026a9a87f3247a031ec7/c1b63/Screenwriter.png 1200w", "/static/6076eede9db0026a9a87f3247a031ec7/29007/Screenwriter.png 1600w", "/static/6076eede9db0026a9a87f3247a031ec7/e67a8/Screenwriter.png 1740w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.reddit.com/r/RoamResearch/comments/imq9qn/my_list_of_public_roam_researches_ive_found/?utm_medium=android_app&utm_source=share"
      }}>{`MY LIST OF PUBLIC ROAM RESEARCHES I`}{`'`}{`VE FOUND!`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/607ed901559b084dd107b9a0458ba29a/9cab2/List-roam-public.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "118.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAAAsSAAALEgHS3X78AAADH0lEQVQ4y21V6XqjMAzkJbqBBGxokgYwxlwh6fH+r6XVyOZId3/MJwWoPJLG08jaltrWUes66rqepvtMfT9InKaZ7nePvh9pGEb57vH8JGMsvb39oThO6HCIV0SWi3VcoK4N2caSc87nfJDjP3b8vuG8rhspgsObxkd8VzHKsibDz6qqpqjnU8GsKN5JF2fS7xdS+XnLOWaZ8lCaFAO5CvlvRDgFp7luIG1G0s2dtJ05zpJnHw23FVPCrcX/IA7YnkVG2uylvYtFwYlyLpijcN3TMdUM9YpT9vr7eGIcJUZVWVKLGVUVIc/rjnTVcRykoC4dR86rXp7jQHSCQ6UjfoeiSbIUbAdy90/q5i9qhpns+CA3fwvM8KDu8UPn2lF6Linl2QqbU+ohzDxQEIiyq6Hs1pIqO2GjeGbCBCjbEPk5x7S4UnI4bEiStdgSI60U5VqTzjI6ZZpOqgjIN+h3fod4pvT9g3ET7FtdW3YsmWG8i5gLy4ton1QA7lMWg1h035S3j5B/Ue74ff+zMt63HhnTsA4HWczNOCqWoWPLGHrIBdh+WAai4lGgG7QOWSFGUDeEjZthm4YKbNnsNsmbXgrl3AGYgqHIizvBtzISgMcjDHH9IG7bdjuG0yqLF2Zgjncv8vG54uVGuKPSMhfrmemlbuWG5EHg8nFgI1jZzp4ts8YyEzYG2fJiAogVX361CNr8hyHyZZZhSTkjzS+rhGSGAFqXG4I5hVktLQmTwGb7/ZSNQ0ZYyPHohS7msCxF2g++B7Y63GlfbF5ltC5F3j195Gfp+UaRN9aOrpX9NZutgBRZW3yKLlFwEfOLDsEMnog5jtNdmOaQym5W2oQ5cjFhh1HgAIjcbu1DkyIbFIMrY+jKjKskxGFkw4/gldNLF5h5EsfBGBK/5cXugSskY6YgmaA3M23t7uYpG2dmEPOyYWn5FGwIUSCGykYh5pl5M10MdZ/vzHU/S5FNzW3jpuAfjtwcdnH9wbYG27pZuQGwNVV6m0MO28Pv5S6vBVFsHCcpCPlg65hpP0x0a0eRDtxHFhL0KMsIEXfY69AX/AtMHrhfJQhV2wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Public roam research databses",
            "title": "Public roam research databses",
            "src": "/static/607ed901559b084dd107b9a0458ba29a/5a190/List-roam-public.png",
            "srcSet": ["/static/607ed901559b084dd107b9a0458ba29a/772e8/List-roam-public.png 200w", "/static/607ed901559b084dd107b9a0458ba29a/e17e5/List-roam-public.png 400w", "/static/607ed901559b084dd107b9a0458ba29a/5a190/List-roam-public.png 800w", "/static/607ed901559b084dd107b9a0458ba29a/9cab2/List-roam-public.png 864w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Articles`}</h2>
    <p><a parentName="p" {...{
        "href": "https://css-tricks.com/beyond-media-queries-using-newer-html-css-features-for-responsive-designs/"
      }}>{`Beyond Media Queries: Using Newer HTML `}{`&`}{` CSS Features for Responsive
Designs | CSS-Tricks`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      